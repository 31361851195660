import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import styled from 'styled-components';

const TermListContainer = styled.section``;
const Card = styled.div``;

export default class TermList extends React.Component {
  render() {
    const { terms, title, taxonomy } = this.props;

    return (
      <TermListContainer className="section">
        <div className="container">
          <div className="content">
            <h1 className="has-text-weight-bold is-size-1">{title}</h1>
          </div>

          <div className="columns is-multiline">
            {terms.map(({ node: term }) => (
              <div className="column is-one-third" key={term.name}>
                <Card className="card">
                  <div className="card-content">
                    <p className="title">
                      <Link to={`/${taxonomy}/${term.slug}`}>{term.name}</Link>
                    </p>
                    <p className="subtitle">{term.description}</p>
                  </div>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </TermListContainer>
    );
  }
}

TermList.propTypes = {
  terms: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  taxonomy: PropTypes.string,
};
